import useFieldApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-field-api'
import { useEffect, useRef, useState } from 'react'
import { Button } from '@patternfly/react-core'
import { CloseButton } from 'react-bootstrap'
import Resizer from 'react-image-file-resizer';
import api from '../utils/api'
import ImageLink from './ImageLink'
import { getFileName, isImage } from '../utils/url'
import styled from 'styled-components';

interface FileObject {
    id: number
    file: string
}

const S = {
    ImageList: styled.div`
        display: flex;
        margin: -6px;
    `,
    ImageWrapper: styled.div`
        display: flex;
        margin: 6px;
    `,
}

const resizeImage = (file: File): Promise<File> => new Promise(resolve => {
    Resizer.imageFileResizer(file, 600, 600, 'JPEG', 100, 0,
        (file: File) => resolve(file),
        'file'
    );
});

const isImageType = (file: File): boolean => (
    file.type.includes("image")
)

export const uploadFile = async (file): Promise<FileObject> => {
    const formData = new FormData()
    const sizedFile = isImageType(file)
        ? await resizeImage(file)
        : file
    formData.append('file', sizedFile)
    const headers = { 'Content-Type': 'multipart/form-data' }
    const resp = await api.post<FileObject>('/files/', formData, { headers })
    return resp.data
}

const FileUploadComponent = (props) => {
    const { input, meta, label } = useFieldApi(props)
    const inputRef = useRef()
    const [files, setFiles] = useState<FileObject[]>(input.value || [])

    const onChange = async (e) => {
        const uploads = await Promise.all(Array.from(e.target.files).map(uploadFile)) as FileObject[]
        setFiles((current) => [...current, ...uploads])
    }

    const amountOfFiles = files.length
    // On change of files, update form state
    useEffect(() => {
        input.onChange(files)
    }, [amountOfFiles])

    // @ts-ignore
    const onClick = () => inputRef?.current?.click()
    const images = files.filter(Boolean).filter(({ file }) => isImage(file))
    const otherFiles = files.filter(Boolean).filter(({ file }) => !isImage(file))
    const removeFile = (file: FileObject) => {
        setFiles((current) => current.filter(({ id }) => id !== file.id))
    }

    return (
        <div>
            <label htmlFor={input.name}>{label}</label>
            <br />
            <input
                type="file"
                style={{ display: 'none' }}
                ref={inputRef}
                multiple
                onChange={onChange}
            />
            <Button
                onClick={onClick}
                variant="secondary"
                style={{ marginBottom: 12 }}
            >
                Upload File
            </Button>
            <br />

            <S.ImageList>
            { images.map((file) => (
                <S.ImageWrapper key={file.id}>
                    <Button
                        onClick={() => removeFile(file)}
                        style={{
                            opacity: 1,
                            marginRight: -30,
                            fill: 'white',
                            color: 'white',
                            backgroundColor: 'black',
                            padding: "3px 6px",
                            height: 30,
                            width: 30,
                            zIndex: 99,
                        }}
                    ><b>X</b></Button>
                    <ImageLink
                        src={file.file}
                        style={{ marginBottom: 12 }}
                    />
                </S.ImageWrapper>
            )) }
            </S.ImageList>

            { otherFiles.map((file) => (
                <p key={file.id}>
                    <CloseButton
                        onClick={() => removeFile(file)}
                        style={{ float: 'left', marginRight: 4 }}
                    />
                    <a
                        href={file.file}
                        target="_blank"
                    >
                        {getFileName(file.file)}
                    </a>
                </p>
            )) }

            { meta.error && (
                <p>
                    <span style={{ color: 'red' }}>{meta.error}</span>
                </p>
            )}
        </div>
    )
}

export default FileUploadComponent
