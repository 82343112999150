import { VehicleType } from './types'

const seizureFields = [
    'discoveredWithPlate',
    'datetimeSeized',
    'digitpolAtSeizure',
    'seizureOfficerName',
    'seizureOfficerContact',
    'isFoundInContainer',
    'foundContainer',
    'isPartOfTelematicsHit',
    'discoveredWithFalseVin',
    'discoveredWithFalsePlate',
    'discoveredWithVin',
    'seizedBy',
    'seizedLocationStreet',
    'seizedLocationPostcode',
    'seizedLocationCity',
    'seizedCountry',
    'signedOff',
]

const telematicsFields = [
    'lastLocatedAt',
    'lastLocatedDate',
    'firstLocatedAt',
    'firstLocatedDate',
    'scmClass',
]

const administrationFields = [
    'id',
    'dateCreated',
    'dateEdited',
    'clientName',
    'registeredByApi',
    'registeredByClient',
    'registeredByEmail',
]

export const detailFields = [
    'branch',
    'plate',
    'processTelematics',
    'connectable',
    'dateProduction',
    'make',
    'model',
    'vinNumber',
    'color',
    'note',
    'status',
    'orderToRetrieve',
    'dateOfTheft',
    'theftLocationStreet',
    'theftLocationCity',
    'isHighImpactCrime',
    'isHighImpactCrime',
    'policeRefNumber',
    'sisNumber',
    'seizedByApi',
    'renterName',
    'locatedCountry',
]

const assetField = ['images', 'files', 'theftReport', 'seizureReports']

const kiwaCertificateFields = [
    'ApprovalNumber',
    'CertificateNumber',
    'ClassCode',
    'ClassDescription',
    'ConfirmationDate',
    'FromFactory',
    'LastControlDate',
    'ModificationDate',
    'PlateNumber',
    'ValidDate',
]

const vbvTrackingFields = ['connectableStatus', 'createdAt', 'lat', 'long']

const rentalReportFields = [
    'lastKnownAddress',
    'trackerFitted',
    'breakdownLocation',
    'clientPhoneNumber',
    'storageEmail',
    'storagePhoneNumber',
    'storageLocation',
    'powerOfAttorney',
    'rentalAgreement',
    'supportingDocs',
    'requestType',
]

const recoveryReportFields = [
    'recoveredDate',
    'recoveredLocation',
    'transportedLocation',
    'finalDestination',
    'recoveryReports',
]

export const isTelematicsField = (field: string) => telematicsFields.includes(field)
export const isSeizureField = (field: string) => seizureFields.includes(field)
export const isAdministrationField = (field: string) => administrationFields.includes(field)
export const isDetailsField = (field: string) => detailFields.includes(field)
export const isAssetField = (field: string) => assetField.includes(field)
export const seizedReportWasFilled = (vehicle: VehicleType) =>
    seizureFields.map((key) => vehicle[key]).filter((v) => v).length > 0
export const isKiwaCertificateFields = (field: string) => kiwaCertificateFields.includes(field)
export const isVbvTrackingFields = (field: string) => vbvTrackingFields.includes(field)
export const isRentalReportFields = (field: string) => rentalReportFields.includes(field)
export const isRecoveryReportFields = (field: string) => recoveryReportFields.includes(field)
