import { format } from "date-fns"
import { LocationHistory } from "./types"

const generateKey = (item) => `${item.lat}__${item.long}`

export function sanitizeLocations(markers: LocationHistory[]) {
    const results: LocationHistory[] = []
    markers.forEach((e, i) => {
        if (i === 0 || i === markers.length - 1) {
            results.push(e)
        } 
        else if (generateKey(e) !== generateKey(markers[i-1]) || generateKey(e) !== generateKey(markers[i+1])) {
            results.push(e)
        }
    })
    return results
}

export function getRepeatedMarkers(markers: LocationHistory[]) {
    const lookup = markers.reduce((a, e) => {
        a[generateKey(e)] = ++a[generateKey(e)] || 0
        return a
      }, {})
      
    const duplicatedMarkers = markers.filter(e => lookup[generateKey(e)])
    const convertedObj = {}
    duplicatedMarkers.forEach(e => {
        if (convertedObj[generateKey(e)]) {
            convertedObj[generateKey(e)].dates.push(e.date)
        } else {
            convertedObj[generateKey(e)] = {
                id: e.id,
                lat: e.lat,
                long: e.long,
                dates: [e.date]
            }
        }
    })
    return Object.values(convertedObj) as LocationHistory[]
}

export function generateLabelForRepeatedMarkers(marker: LocationHistory, ignoreDate?: boolean) {
    let dateStr = ''
    marker.dates.forEach((e, i) => {
        dateStr += `${i+1}. ${format(new Date(e), `${ignoreDate? 'HH:mm' : 'MMM dd - HH:mm'}`)}\n`
    })

    return dateStr
}
