import { useState } from 'react'
import useFieldApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-field-api'
import { TimePicker } from '@patternfly/react-core'
import { toDate } from '../utils/datetime'
import styled from 'styled-components'

const S = {
    Label: styled.label`
        display: block;
        margin-bottom: 2px;
        font-weight: 700;
        font-size: 14px;
    `
}

const DateTimeComponent = (props) => {
    const { input, meta, label } = useFieldApi(props)
    const [initialDate, initialTime] = input.value.replace('Z', '').split('T')
    const [date, setDate] = useState(initialDate)
    const [time, setTime] = useState(initialTime)

    const onDateChange = (e) => {
        const value = e.target.value
        const date = toDate(value).toISOString().split('T')[0]
        setDate(date)
        input.onChange(`${date}T${time}Z`)
    }

    const onTimeChange = (e) => {
        const value = e
        setTime(value)
        input.onChange(`${date}T${value}Z`)
    }

    return (
        <div>
            <S.Label htmlFor={`${input.name}date`}>{label}</S.Label>
            <input
                style={{marginRight: 12}}
                type="date"
                id={`${input.name}date`}
                value={date}
                onChange={onDateChange}
            />
            <TimePicker
                type="time"
                id={`${input.name}time`}
                defaultTime={time}
                onChange={onTimeChange}
                is24Hour
            />
            <br />
            { meta.error && (
                <div>
                    <span style={{ color: 'red' }}>{meta.error}</span>
                </div>
            )}
        </div>
    )
}

export default DateTimeComponent
