import {PublicUser} from "../users/types";

export default (user: PublicUser) => user ?
    [
        user.firstName,
        user.lastName,
    ]
        .filter(Boolean)
        .join(' ') || user.email
    : 'System'
