import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Popover,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SortIcon from '@mui/icons-material/Sort'
import React, { useCallback, useEffect, useState } from 'react'
import api from '../../utils/api'
import { GeoLocation } from '../vehicles/types'
import styled, { css } from 'styled-components'
import * as Sentry from '@sentry/react'
import { format } from 'date-fns'
import { Search } from '@mui/icons-material'
import useDebounce from '../../hooks/useDebounce'
import NextPrevPagination from '../../components/NextPrevPagination'
import Map from './Map'
import useRefreshSecondAgo from '../../hooks/useRefreshSecondAgo'
import { Link, useHistory, useLocation } from 'react-router-dom'

const S = {
    InfoBox: styled.div`
        width: 320px;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 99;
        background: rgba(255, 255, 255, 0.8);
        padding: 8px 16px;
        border-radius: 6px;
        font-size: 14px;
    `,
    Sidebar: styled.div<{ collapse?: boolean; isSingle?: boolean }>`
        position: fixed;
        top: 70px;
        left: 6px;
        display: grid;
        grid-template-rows: ${({ collapse }) => (collapse ? '24px' : '48px 1fr 48px 20px')};
        width: 300px;
        height: ${({ collapse }) => (collapse ? '44px' : '85vh')};
        padding: 12px;
        padding-bottom: 16px;
        overflow: hidden;
        border-radius: 8px;
        background: #0e232a;

        ${({ isSingle }) =>
            isSingle &&
            css`
                grid-template-rows: 1fr 20px;
            `}

        @media (max-width: 768px) {
            width: 180px;
            & *:not(svg) {
                font-size: 11px !important;
            }
            & svg {
                font-size: 16px !important;
            }
        }
    `,
    VehicleItem: styled.div<{ isActive?: boolean, isStolen?: boolean }>`
        width: 100%;
        margin-bottom: 8px;
        padding: 8px;
        background: ${({isStolen}) => (isStolen ? '#1c586d' : 'green')};
        color: white;
        border-radius: 3px;
        border-left: 5px solid ${({ isActive }) => (isActive ? '#0479cf' : 'transparent')};
        user-select: none;
        cursor: pointer;
        svg {
            font-size: 16px;
        }
    `,
    RefreshIndicator: styled.div<{ refreshAgo: number }>`
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      background: #1c586d;
      color: gray;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      & > div {
        width: ${({refreshAgo}) => `${(refreshAgo / 60) * 100}%`};
        height: 24px;
        white-space: nowrap;
        background: #2288ab;
        transition: 0.5s;
      }

      & > p {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
      }
    `,
    CollapseButton: styled.div`
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 22px;
        background: white;
        display: grid;
        place-items: center;
        cursor: pointer;
    `,
    List: styled.div`
        margin-top: 10px;
        padding-right: 4px;
        overflow: auto;
    `,
    HideHistory: styled.div`
        position: absolute;
        top: 68px;
        right: 8px;
        font-weight: bold;
        color: white;
        background: #1c586d;
        border-radius: 4px;
        padding: 4px 8px;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            background: #18779a;
        }
    `
}

const LIMIT = 999

const MapView = () => {
    const location = useLocation()
    const history = useHistory()
    const params = new URLSearchParams(location.search)
    const vehicleId = params.get('vehicleId')
    const mode = params.get('mode')
    const [markers, setMarkers] = useState<GeoLocation[]>([])
    const [selectedMarkerId, setSelectedMarkerId] = useState<number | undefined>(undefined)
    const { count: refreshAgo, reset } = useRefreshSecondAgo(60)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search)
    const [collapse, setCollapse] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const [sortBy, setSortBy] = useState('created')
    const [showAll, setShowAll] = useState(false)
    const handleSort = (e) => {
        setSortBy(e.target.value)
        setAnchorEl(null)
    }

    const openSortMenu = Boolean(anchorEl)

    useEffect(() => {
        async function fetchData() {
            reset()

            if (mode === 'detail') {
                const resp = await api.get<GeoLocation>(`/vehicles/${vehicleId}/location/`)
                if (resp.data) {
                    setMarkers([resp.data])
                    setSelectedMarkerId(resp.data.id)
                    setTotal(1)
                }
                return
            }

            const resp = await api.get<{ results: GeoLocation[]; count: number }>(
                `/vehicles/locations/?offset=${(page - 1) * LIMIT}&limit=${LIMIT}&search=${debouncedSearch}&sort=${sortBy}&all=${showAll ? 'true' : ''}`
            )
            if (resp.data) {
                setMarkers(resp.data.results)
                if (resp.data.results?.length) {
                    setSelectedMarkerId((id) => id || resp.data.results[0].id)
                }
                setTotal(resp.data.count)
            }
        }

        fetchData()

        const fetchDataInterval = setInterval(() => {
            fetchData()
        }, 60 * 1000)

        return () => clearInterval(fetchDataInterval)
    }, [page, debouncedSearch, sortBy, showAll])

    const handleClickVehicle = useCallback(
        (item: GeoLocation) => {
            if (mode !== 'detail') {
                history.push(`/map?vehicleId=${item.vehicle.id}`)
            }
            setSelectedMarkerId(item.id)
        },
        [],
    )

    return (
        <Box width={'100vw'} height={'calc(100vh - 95px)'}>
            <Map
                markers={markers}
                selectedMarkerId={selectedMarkerId}
                handleClickVehicle={handleClickVehicle}
                vehicleId={vehicleId}
            />
            <S.Sidebar collapse={collapse} isSingle={mode === 'detail'}>
                {mode !== 'detail' && !collapse && (
                    <Box display="flex" alignItems="center">
                        <TextField
                            id="input-with-icon-textfield"
                            label="Search by plate"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            variant="filled"
                            size="small"
                            fullWidth
                            sx={{ background: 'white' }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <IconButton
                            edge="end"
                            color="info"
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            aria-label="close"
                            sx={{ ml: 1 }}
                        >
                            <SortIcon />
                        </IconButton>
                        <Popover
                            open={openSortMenu}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <FormControl sx={{ p: 2 }}>
                                <Typography>Sort by</Typography>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={sortBy}
                                    onChange={handleSort}
                                >
                                    <FormControlLabel
                                        sx={{ mb: 0, mr: 0, '.MuiFormControlLabel-label': {fontSize: '12px'} }}
                                        value="date"
                                        control={<Radio />}
                                        label="Latest position"
                                    />
                                    <FormControlLabel
                                        sx={{ mb: 0, mr: 0, '.MuiFormControlLabel-label': {fontSize: '12px'} }}
                                        value="created"
                                        control={<Radio />}
                                        label="Latest received position"
                                    />
                                </RadioGroup>
                                <Divider />
                                <FormControlLabel
                                    sx={{mt: 2}}
                                    control={<Checkbox checked={showAll} onChange={(e) => setShowAll(e.target.checked)} />}
                                    label="Show all vehicles"
                                />
                            </FormControl>
                        </Popover>
                    </Box>
                )}

                <S.List>
                    {markers.map((item) => (
                        <S.VehicleItem
                            isActive={item.id === selectedMarkerId}
                            onClick={() => handleClickVehicle(item)}
                            key={item.id}
                            isStolen={item.vehicle?.status === 'stolen'}
                        >
                            <Link
                                to={`/vehicles/${item.vehicle.id}`}
                                style={{color: 'white'}}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Typography variant="body1">
                                    {item.vehicle.plate} - {item.vehicle.model}
                                </Typography>
                            </Link>

                            <Typography variant="body2" sx={{ my: 0.5 }}>
                                <LocationOnIcon className="mr-1" />
                                {item.location || `${item.lat} ${item.long}`}
                            </Typography>
                            <Typography variant="caption" fontStyle="italic" color="#43b0f4">
                                <AccessTimeIcon style={{ marginTop: -2, marginRight: 4 }} />
                                {format(new Date(item.date), 'MMM dd yyyy - HH:mm')}
                            </Typography>

                            {item.vehicle?.status === 'stolen' && (
                                <>
                                    <Divider sx={{borderColor: 'white', mt: 1}} />
                                    <Typography variant="overline">
                                        Stolen at
                                    </Typography>
                                    <Typography variant="body2">
                                        <LocationOnIcon className="mr-1" />
                                        {`${item.vehicle.theftLocationStreet} ${item.vehicle.theftLocationCity}`}
                                    </Typography>
                                    <Typography variant="caption" fontStyle="italic" color="#43b0f4">
                                        <AccessTimeIcon style={{ marginTop: -2, marginRight: 4 }} />
                                        {format(new Date(item.vehicle.dateOfTheft), 'MMM dd yyyy - HH:mm')}
                                    </Typography>
                                </>
                            )}
                        </S.VehicleItem>
                    ))}
                </S.List>
                {mode !== 'detail' && (
                    <NextPrevPagination
                        limit={LIMIT}
                        page={page}
                        onPageChange={setPage}
                        total={total}
                    />
                )}
                <S.CollapseButton onClick={() => setCollapse(!collapse)}>
                    <KeyboardArrowUpIcon style={{ transform: `rotate(${collapse ? 180 : 0}deg)` }} />
                </S.CollapseButton>

                <S.RefreshIndicator refreshAgo={refreshAgo}>
                    <div />
                    <p>
                        <CachedIcon style={{ color: 'gray' }} /> {refreshAgo}s ago
                    </p>
                </S.RefreshIndicator>
            </S.Sidebar>
            {mode !== 'detail' && vehicleId && (
                <S.HideHistory onClick={() => history.push('/map')}>
                    Close vehicle view
                </S.HideHistory>
            )}
        </Box>
    )
}

export default Sentry.withErrorBoundary(MapView, { fallback: <p className="error"></p> })
