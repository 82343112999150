import pf4ComponentMapper from '@data-driven-forms/pf4-component-mapper/dist/cjs/component-mapper'
import FileUploadComponent from '../../components/FileUpload'
import DateTimeComponent from '../../components/DateTimePicker'
import DisabledTextFieldComponent from '../../components/DisabledTextField'
import FormHeader from '../../components/FormHeader'
import startCase from 'lodash/startCase'

const labels = {
    id: 'Document Number',
    registeredByApi: 'Registered By Api',
    dateCreated: 'Date Created',
    dateEdited: 'Date Edited',
    clientName: 'Client Name',
    plate: 'License Plate',
    make: 'Make',
    model: 'Model',
    dateProduction: 'Date of Production',
    color: 'Color',
    firstLocatedAt: 'First located at',
    firstLocatedDate: 'Date/Time of First location',
    seizedByApi: 'Reported seized by third party (API)',
    lastLocatedAt: 'Last located at',
    lastLocatedDate: 'Date/Time of Last location',
    seizedBy: 'Seized by (eg Police Force)',
    seizedCountry: 'Country of Seizure',
    seizedLocationStreet: 'Street name of the Seizure',
    seizedLocationPostcode: 'Postal code of the Seizure',
    seizedLocationCity: 'City name of the Seizure',
    theftLocationStreet: 'Street name of Theft',
    theftLocationPostcode: 'Postcode of the Theft',
    theftLocationCity: 'City name of the Theft',
    theftLocationCountry: 'Country name of the Theft',
    dateOfTheft: 'Date of Theft',
    numberOfDoors: 'Number of Doors',
    invoicedToClient: 'Invoiced to client',
    numberOfWheels: 'Number of Wheels',
    theftReport: 'Theft Report',
    discoveredWithFalsePlate: 'Discovered with Falsified License Plate',
    discoveredWithFalseVin: 'Discovered with Falsified VIN',
    discoveredWithVin: 'Discovered with VIN',
    files: 'Documents',
    images: 'Vehicle Images',
    vinNumber: 'Vehicle Identification Number (VIN)',
    sisNumber: 'SIS Number',
    policeRefNumber: 'Police Ref Number',
    orderToRetrieve: 'Order To Retrieve',
    processTelematics: 'Process Telematics',
    connectable: 'Connectable',
    wamInsured: 'WAM Insured',
    isHighImpactCrime: 'High Impact Crime',
    discoveredWithPlate: 'Discovered With License Plate',
    datetimeSeized: 'Date / Time of the Seizure',
    status: 'Status',
    seizureOfficerName: 'Seizing Officer Name',
    seizureOfficerContact: 'Seizing Officer Contact',
    digitpolAtSeizure: 'An investigation led by Digitpol has resulted in the Seizure',
    isFoundInContainer: 'Car is found in container',
    foundContainer: 'Found container',
    isPartOfTelematicsHit: 'Car was seized as part of a secondary vehicle telematics HIT',
    registeredByClient: 'Registered by Client',
    registeredByEmail: 'Registered by Email',
    note: 'Intelligence',
    seizureReports: 'Seizure Reports',
    signedOff: 'Seizure Signoff Status',
    scmClass: 'SCM Class',
    ApprovalNumber: 'Approval Number',
    CertificateNumber: 'Certificate Number',
    ClassCode: 'Class Code',
    ClassDescription: 'Class Description',
    ConfirmationDate: 'Confirmation Date',
    FromFactory: 'From Factory',
    LastControlDate: 'Last Control Date',
    ModificationDate: 'Modification Date',
    PlateNumber: 'Plate Number',
    ValidDate: 'Valid Date',
    kiwaCertificate: 'Kiwa Certificate',
    createdAt: 'Date',
    connectableStatus: 'Connectable Status',
    requestType: 'Request',
}

export const statusOptions = [
    { label: 'Not Stolen', value: 'not_stolen' },
    { label: 'Stolen', value: 'stolen' },
    { label: 'In Process', value: 'in_process' },
    { label: 'Seized', value: 'seized' },
]

export const connectableOptions = [
    { label: 'Pending', value: 'PENDING' },
    { label: 'Connectable', value: 'CONNECTABLE' },
    { label: 'Not connectable', value: 'NOTCONNECTABLE' },
]

export const branchOptions = [
    { label: 'Sixt Germany', value: 'Sixt Germany' },
    { label: 'Sixt Austria', value: 'Sixt Austria' },
    { label: 'Sixt France', value: 'Sixt France' },
    { label: 'Sixt Spain', value: 'Sixt Spain' },
    { label: 'Sixt Italy', value: 'Sixt Italy' },
    { label: 'Sixt Benelux', value: 'Sixt Benelux' },
    { label: 'Sixt Switzerland', value: 'Sixt Switzerland' },
    { label: 'Sixt Franchise', value: 'Sixt Franchise' },
]

export const locatedCountryOptions = [
    { label: 'Unknown', value: 'Unknown' },
    { label: 'Croatia', value: 'Croatia' },
    { label: 'Serbia', value: 'Serbia' },
    { label: 'Hungary', value: 'Hungary' },
    { label: 'Poland', value: 'Poland' },
    { label: 'Lithuania', value: 'Lithuania' },
    { label: 'Latvia', value: 'Latvia' },
    { label: 'Italy', value: 'Italy' },
    { label: 'Spain', value: 'Spain' },
    { label: 'France', value: 'France' },
    { label: 'Germany', value: 'Germany' },
    { label: 'Netherlands', value: 'Netherlands' },
    { label: 'Belgium', value: 'Belgium' },
    { label: 'Luxembourg', value: 'Luxembourg' },
    { label: 'United Kingdom', value: 'United Kingdom' },
    { label: 'Ghana', value: 'Ghana' },
    { label: 'Togo', value: 'Togo' },

]

export const fields = [
    { component: 'header', name: 'Vehicle Info', label: 'Vehicle Info' },
    { component: 'select', name: 'branch', simpleValue: true, options: branchOptions },
    { component: 'text-field', name: 'plate', isRequired: true },
    { component: 'text-field', name: 'make', isRequired: true },
    { component: 'text-field', name: 'model', isRequired: true },
    { component: 'date-picker', name: 'dateProduction', isRequired: true },
    { component: 'text-field', name: 'color' },
    {
        component: 'select',
        name: 'status',
        simpleValue: true,
        options: statusOptions,
    },
    { component: 'text-field', name: 'numberOfDoors', type: 'number' },
    { component: 'text-field', name: 'numberOfWheels', type: 'number' },
    { component: 'date-time-picker', name: 'dateOfTheft' },
    { component: 'text-field', name: 'vinNumber' },
    { component: 'text-field', name: 'sisNumber' },
    { component: 'text-field', name: 'policeRefNumber' },
    { component: 'text-field', name: 'clientName' },
    { component: 'checkbox', name: 'orderToRetrieve' },
    { component: 'checkbox', name: 'processTelematics' },
    {
        component: 'select',
        name: 'connectable',
        simpleValue: true,
        options: connectableOptions,
        condition: {
            when: 'make',
            pattern: 'TOYOTA',
            flags: 'i',
            notMatch: true,
        },
    },
    { component: 'checkbox', name: 'wamInsured' },

    // Extra
    { component: 'textarea', name: 'note' },
    { component: 'text-field', name: 'renterName' },
    { component: 'checkbox', name: 'invoicedToClient' },

    // Seizure info
    { component: 'header', name: 'Seizure Info', label: 'Seizure Info' },
    { component: 'text-field', name: 'seizedBy' },
    { component: 'text-field', name: 'seizedCountry' },
    { component: 'text-field', name: 'seizedLocationStreet' },
    { component: 'text-field', name: 'seizedLocationPostcode' },
    { component: 'text-field', name: 'seizedLocationCity' },
    { component: 'text-field', name: 'theftLocationStreet' },
    { component: 'text-field', name: 'theftLocationPostcode' },
    { component: 'text-field', name: 'theftLocationCity' },
    { component: 'text-field', name: 'theftLocationCountry' },
    { component: 'date-time-picker', name: 'datetimeSeized' },
    { component: 'text-field', name: 'seizureOfficerName' },
    { component: 'text-field', name: 'seizureOfficerContact' },
    { component: 'checkbox', name: 'digitpolAtSeizure' },
    { component: 'checkbox', name: 'isFoundInContainer' },
    {
        component: 'text-field',
        name: 'foundContainer',
        condition: {
            when: 'isFoundInContainer',
            is: true,
        },
    },
    { component: 'checkbox', name: 'isPartOfTelematicsHit' },
    { component: 'checkbox', name: 'discoveredWithFalseVin' },
    {
        component: 'text-field',
        name: 'discoveredWithVin',
        condition: {
            when: 'discoveredWithFalseVin',
            is: true,
        },
    },
    { component: 'checkbox', name: 'discoveredWithFalsePlate' },
    {
        component: 'text-field',
        name: 'discoveredWithPlate',
        condition: {
            when: 'discoveredWithFalsePlate',
            is: true,
        },
    },
    { component: 'checkbox', name: 'seizedByApi' },
    { component: 'checkbox', name: 'signedOff' },

    // Recovery Report
    { component: 'header', name: 'Recovery report', label: 'Recovery report' },
    { component: 'date-picker', name: 'recoveredDate' },
    { component: 'text-field', name: 'recoveredLocation' },
    { component: 'text-field', name: 'transportedLocation' },
    { component: 'text-field', name: 'finalDestination' },
    {
        component: 'file-upload',
        label: 'Recovery Reports',
        name: 'recoveryReports',
        multiple: true,
    },

    // Telematics info
    { component: 'header', name: 'Telematics / GPS Data', label: 'Telematics / GPS Data' },
    { component: 'text-field', name: 'lastLocatedAt' },
    { component: 'date-time-picker', name: 'lastLocatedDate' },
    { component: 'select', name: 'locatedCountry', simpleValue: true, options: locatedCountryOptions },
    { component: 'text-field', name: 'firstLocatedAt' },
    { component: 'date-time-picker', name: 'firstLocatedDate' },
    {
        component: 'file-upload',
        label: 'Screenshots',
        name: 'screenshots',
        multiple: true,
    },

    // Files
    { component: 'header', name: 'Files', label: 'Files' },
    {
        component: 'file-upload',
        label: 'Images of Vehicle',
        name: 'images',
        multiple: true,
    },
    {
        component: 'file-upload',
        label: 'Documents',
        name: 'files',
        multiple: true,
    },
    {
        component: 'file-upload',
        label: 'Theft Report',
        name: 'theftReport',
        multiple: true,
    },
    {
        component: 'file-upload',
        label: 'seizure Reports',
        name: 'seizureReports',
        multiple: true,
    },
    {
        component: 'file-upload',
        label: 'POA',
        name: 'rentalReport.powerOfAttorney',
        multiple: true,
        condition: {
            when: 'rentalReport',
            isNotEmpty: true,
        },
    },
    {
        component: 'file-upload',
        label: 'Rental Agreement',
        name: 'rentalReport.rentalAgreement',
        multiple: true,
        condition: {
            when: 'rentalReport',
            isNotEmpty: true,
        },
    },
    {
        component: 'file-upload',
        label: 'Supporting Docs',
        name: 'rentalReport.supportingDocs',
        multiple: true,
        condition: {
            when: 'rentalReport',
            isNotEmpty: true,
        },
    },
].map((field) => ({ label: labels[field.name] || startCase(field.name), ...field }))

const missingLabels = fields.filter(({ label }) => !label).map(({ name }) => name)
if (missingLabels.length) {
    console.warn(`Missing labels: ${missingLabels.join(',')}`)
}

export const fieldToLabel = (field: string): string => labels[field] || startCase(field)

export const validatorMapper = {}

export const componentMapper = {
    ...pf4ComponentMapper,
    'file-upload': FileUploadComponent,
    'date-time-picker': DateTimeComponent,
    'disabled-text-field': DisabledTextFieldComponent,
    header: FormHeader,
}
